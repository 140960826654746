var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "progress-analysis"
  }, [_c('b-row', [_c('b-col', {
    staticClass: "card-bg card-left text-center",
    attrs: {
      "lg": "3",
      "align-h": "center"
    }
  }, [_c('b-skeleton', {
    staticClass: "mt-3",
    attrs: {
      "width": "60%"
    }
  }), _c('b-skeleton', {
    staticClass: "mt-3",
    attrs: {
      "width": "30%"
    }
  }), _c('b-skeleton-img', {
    staticClass: "mt-2",
    attrs: {
      "height": "20"
    }
  }), _c('b-skeleton', {
    staticClass: "mt-3",
    attrs: {
      "width": "30%"
    }
  }), _c('b-skeleton', {
    staticClass: "mt-5",
    attrs: {
      "width": "90%"
    }
  }), _c('b-skeleton', {
    staticClass: "mt-3",
    attrs: {
      "width": "90%"
    }
  }), _c('b-skeleton', {
    staticClass: "mt-3",
    attrs: {
      "width": "90%"
    }
  })], 1), _c('b-col', {
    staticClass: "card-right",
    attrs: {
      "lg": "8",
      "cols": "12",
      "offset-lg": "1"
    }
  }, [_c('div', {
    staticClass: "display-center"
  }, [_c('b-skeleton', {
    staticClass: "mt-3",
    attrs: {
      "width": "30%"
    }
  }), _c('b-skeleton', {
    staticClass: "mt-3",
    attrs: {
      "width": "60%"
    }
  }), _c('b-skeleton-img', {
    staticClass: "mt-2",
    attrs: {
      "height": "700"
    }
  }), _c('b-row', {
    staticClass: "mt-3"
  }, [_c('b-col', [_c('b-skeleton', {
    attrs: {
      "type": "button"
    }
  })], 1), _c('b-col', [_c('b-skeleton', {
    attrs: {
      "type": "button"
    }
  })], 1), _c('b-col', [_c('b-skeleton', {
    attrs: {
      "type": "button"
    }
  })], 1)], 1)], 1)])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
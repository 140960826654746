var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "download-certificate-modal",
      "size": "sm",
      "ok-title": _vm.$t('confirmDownload'),
      "cancel-title": _vm.$t('close'),
      "title": _vm.$t('my') + _vm.$t('upgradeCertificate'),
      "centered": "",
      "hide-header-close": "",
      "header-bg-variant": "primary"
    },
    on: {
      "ok": function ($event) {
        return _vm.queryLevelCertificate();
      }
    }
  }, [_c('span', {
    staticClass: "modal-Certificate-text"
  }, [_vm._v(_vm._s(`${_vm.$t('download')} Level ${_vm.downloadCertificateData.nowLevel} ${_vm.$t('upgradeCertificate')} `))])]);

}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "wuwow card-body card-bg",
    staticStyle: {
      "padding": "0",
      "width": "100%"
    }
  }, [_c('div', {
    staticClass: "progress-grade-swiper"
  }, [_c('swiper', {
    key: _vm.analysisOutlineData.nowLevel,
    ref: "mySwiper",
    attrs: {
      "options": _vm.swiperOption
    }
  }, [_vm._l(_vm.swiperList, function (item) {
    return _c('swiper-slide', {
      key: item.level,
      staticClass: "d-flex justify-content-center py-lg-0 py-4"
    }, [_c('div', {
      staticClass: "text-center d-flex flex-column justify-content-center align-items-center"
    }, [_c('div', {
      staticClass: "d-flex align-items-end justify-content-center",
      staticStyle: {
        "height": "10%"
      }
    }, [_c('span', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: item.level === _vm.analysisOutlineData.originLevel,
        expression: "item.level === analysisOutlineData.originLevel"
      }],
      staticClass: "initial-level px-3"
    }, [_vm._v(" " + _vm._s(`${_vm.$t('originLevel')}`))]), _c('span', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: item.level === _vm.analysisOutlineData.nowLevel,
        expression: "item.level === analysisOutlineData.nowLevel"
      }],
      staticClass: "current-level px-3"
    }, [_vm._v(" " + _vm._s(`${_vm.$t('currentLevel')}`))])]), _c('div', {
      staticClass: "level",
      class: {
        'level-un': item.level > _vm.analysisOutlineData.nowLevel || item.level < _vm.analysisOutlineData.originLevel
      }
    }, [_vm._v(_vm._s(`Level${item.level}`))]), _c('div', {
      staticClass: "circle d-flex justify-content-center align-items-center flex-column my-lg-2 my-1",
      class: {
        'level-un': item.level > _vm.analysisOutlineData.nowLevel || item.level < _vm.analysisOutlineData.originLevel
      }
    }, [_c('div', [_vm._v(_vm._s(_vm.$t('finished')))]), _c('div', [_c('span', [_vm._v(_vm._s(item.class))]), _c('span', {
      attrs: {
        "clelsv-elses": "circle-unit"
      }
    }, [_vm._v(_vm._s(`${_vm.$t('class.class')}`))])])]), item.level > 1 ? _c('div', {
      staticClass: "avg-upgrade"
    }, [_vm._v(_vm._s(`${_vm.$t('average')}${_vm.$t('upgrade_test.upgrade')}${_vm.$t('class.class_count')}:${item.avgUpgrade}${_vm.$t('class.class')}`))]) : _vm._e(), _c('div', {
      staticClass: "d-flex align-items-start justify-content-center",
      staticStyle: {
        "height": "10%"
      }
    }, [_vm.canDownloadCertificate(item.level) ? _c('button', {
      directives: [{
        name: "b-modal",
        rawName: "v-b-modal.download-certificate-modal",
        modifiers: {
          "download-certificate-modal": true
        }
      }],
      staticClass: "btn btn-primary btn-sm btn-certificate",
      on: {
        "click": function ($event) {
          return _vm.setCertificateModalData(item.level);
        }
      }
    }, [_vm._v(_vm._s(`${_vm.$t('download')}${_vm.$t('upgrade_test.upgrade')}${_vm.$t('upgrade_test.certificate')}`))]) : _vm._e()])])]);
  }), _c('div', {
    staticClass: "swiper-pagination",
    attrs: {
      "slot": "pagination"
    },
    slot: "pagination"
  }), _c('font-awesome-icon', {
    staticClass: "title-btn swiper-button-prev",
    attrs: {
      "slot": "button-prev",
      "icon": "caret-left",
      "size": "5x"
    },
    slot: "button-prev"
  }), _c('font-awesome-icon', {
    staticClass: "title-btn swiper-button-next",
    attrs: {
      "slot": "button-next",
      "icon": "caret-right",
      "size": "5x"
    },
    slot: "button-next"
  })], 2)], 1), _c('download-certificate-modal', {
    key: _vm.downloadCertificateData.nl,
    attrs: {
      "downloadCertificateData": _vm.downloadCertificateData
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
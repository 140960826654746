<template lang="pug">
div
  .wuwow.card-body.card-bg
    .card-body-title {{ $t('material.material_analysis') }}
    #barChart.are-progress-chart
      bar-chart(:chart-data='dataCollection' :options='options')
    .crowns
      .crown(v-for='(item, index) in barChartLength' :key='index')
        img.crown-img(:src='crownImg' v-if="crownPosition === index")

</template>

<script type="text/javascript">
import barChart from '@/components/chart/barChart';
import progressAnalysisMixin from '@/components/vip/mixin/progressAnalysisMixin.vue';
import urlConstant from '@/constants/url';
import progressAnalysisConstants from '@/constants/progressAnalysis';

export default {
  name: 'VipMaterialAnalysis',

  components: {
    barChart,
  },

  mixins: [progressAnalysisMixin],

  props: {
    materialAnalysisData: {
      type: Object,
      default: function() {
        return {};
      },
    },
  },

  data() {
    return {
      crownImg: urlConstant.IMAGE.CROWN,
      dataCollection: {},
      barChartLength: 0,
      crownPosition: [],
    };
  },

  computed: {},

  watch: {},

  mounted(){
    this.setMaterialAnalysisChartData();
  },

  methods: {
    setMaterialAnalysisChartData(){
      const labels = this.setSuitableLabelData(),
            data = this.setChartDataAndOptions();
      this.dataCollection = {
        labels,
        datasets: [
          {
            label: this.$t('classes'),
            backgroundColor: progressAnalysisConstants.CHART.DATA_COLOR,
            borderColor: progressAnalysisConstants.CHART.DATA_COLOR,
            data,
            fill: false,
          },
        ],
      };
    },

    setSuitableLabelData(){
      const labels = [];
      // eslint-disable-next-line
      for (const item in this.materialAnalysisData) {
        labels.push(item.substr(0, item.match('[A-Za-z]').index - 1));
      }
      return labels;
    },

    setChartDataAndOptions(){
      const data = [];
      // eslint-disable-next-line
      for (const item in this.materialAnalysisData) {
        data.push(this.materialAnalysisData[item]);
      }
      this.getFirstMaterial(data);
      this.options.scales.yAxes[0].ticks.max = 100;
      return data;
    },

    getFirstMaterial(data){
      this.barChartLength = data.length;
      const dataMax = Math.max(...data);
      for (let index = 0; index < data.length; index++) {
        if (data[index] === dataMax){
          this.crownPosition = index;
        }
      }
    },
  },
};
</script>

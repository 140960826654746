export default {
  UPGRADE_CHALLENGE_LIMITS: 9,
  CONSULTANT_RECOMMEND_COUNT: { // 顧問推薦數
    ZERO: 0,
    FIRST: 1,
    SECOND: 2,
    THIRD: 3,
  },
  AVERAGE_UPGRADE: { // 平均升等堂數
    LEVEL_ONE: null,
    LEVEL_TWO: 148,
    LEVEL_THREE: 167,
    LEVEL_FOUR: 184,
    LEVEL_FIVE: 192,
    LEVEL_SIX: 194,
    LEVEL_SEVEN: 232,
    LEVEL_EIGHT: 260,
    LEVEL_NINE: 320,
    LEVEL_TEN: 330,
    LEVEL_ELEVEN: 350,
    LEVEL_TWELVE: 360,
  },
};

var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "wuwow card-body",
    staticStyle: {
      "padding": "0px"
    }
  }, [_c('div', {
    staticClass: "card-body-top"
  }, [_c('span', {
    staticClass: "title-avg"
  }, [_vm._v(_vm._s(_vm.learningPassionData.thirtyDaysAverage))]), _c('span', {
    staticClass: "title-unit mr-2"
  }, [_vm._v(_vm._s(_vm.$t('class.class')) + "/" + _vm._s(_vm.$t('day')))])]), _c('div', {
    staticClass: "d-flex flex-row card-body-btm flex-wrap"
  }, [_c('div', {
    staticClass: "card-body-btm-l"
  }, [_c('canvas', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.fullWidth > _vm.PadWidth,
      expression: "fullWidth > PadWidth"
    }],
    staticClass: "canvas",
    attrs: {
      "id": "canvas-attendance",
      "width": "320",
      "height": "320"
    }
  }), _c('canvas', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.fullWidth <= _vm.PadWidth,
      expression: "fullWidth <= PadWidth"
    }],
    staticClass: "canvas",
    attrs: {
      "id": "canvas-attendance-mobile",
      "width": "320",
      "height": "200"
    }
  }), _c('div', {
    staticClass: "canvas-text"
  }, [_c('span', {
    staticClass: "canvas-digital"
  }, [_vm._v(_vm._s(_vm.learningPassionData.thirtyDaysShowPercentage))]), _c('span', {
    staticClass: "canvas-unit"
  }, [_vm._v("%")])]), _c('div', {
    staticClass: "canvas-title"
  }, [_vm._v(_vm._s(_vm.$t('vip.attendance_rate')))])]), _c('div', {
    staticClass: "card-body-btm-r flex-column bd-highlight"
  }, _vm._l(_vm.attendDataList, function (list) {
    return _c('div', {
      key: list.text
    }, [_c('div', {
      staticClass: "btm-r-list btm-r-list-frequency d-flex align-items-baseline",
      class: list.class
    }, [_c('div', {
      staticClass: "right-digital col-7"
    }, [_vm._v(_vm._s(list.value)), _c('span', {
      staticClass: "right-unit"
    }, [_vm._v(_vm._s(_vm.$t('class.class')))])]), _c('div', {
      staticClass: "right-text col-5"
    }, [_vm._v(_vm._s(list.text))])])]);
  }), 0)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }
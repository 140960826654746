export default {
  methods: {
    drawCanvas(id, percent, radius, lineWidth) {
      let percentOverOne = false;
      if (percent > 100) {
        percent = percent - 100;
        percentOverOne = true;
      }
      const can = document.getElementById(id),
        c = can.getContext('2d');
      // grd = c.createLinearGradient(0, 0, 170, 0);

      const posX = can.width / 2, // 中心x坐标
        posY = can.height / 2, // 中心y坐标
        fps = 1000 / 200,
        // procent = 0,
        oneProcent = 360 / 100,
        result = oneProcent * percent;

      c.lineCap = 'round';
      let deegres = 0;
      const acrInterval = setInterval(function() {
        deegres += 1;
        c.clearRect(0, 0, can.width, can.height);

        // spanProcent.innerHTML = procent.toFixed();
        // 內圈

        c.beginPath();
        // 第三個參數是半徑
        c.arc(posX, posY, radius, (Math.PI / 180) * 270, (Math.PI / 180) * (270 + 360));

        c.strokeStyle = '#e1eaf0';
        c.lineWidth = lineWidth;
        c.stroke();
        // 外圈
        c.beginPath();
        c.lineWidth = lineWidth;
        // 顏色漸層
        if (id === 'canvas-main' || percentOverOne) {
          const linGrad = c.createLinearGradient(
            posX,
            posY - radius - c.lineWidth,
            posX,
            posY + radius + c.lineWidth
          );
          // 黃色
          linGrad.addColorStop(0.0, '#fbee25');
          // 紅色
          linGrad.addColorStop(0.6, '#e5395d');
          // 藍
          linGrad.addColorStop(0.9, '#00a3e4');
          // 黃色
          linGrad.addColorStop(0.1, '#fbee25');
          c.strokeStyle = linGrad;
        } else {
          c.strokeStyle = '#1255a0';
        }
        // 圓弧
        if (percent !== 0) {
          c.arc(
            posX,
            posY,
            radius,
            (Math.PI / 180) * 270,
            (Math.PI / 180) * (270 + deegres)
          );
        }
        c.stroke();
        if (deegres >= result) {
          clearInterval(acrInterval);
        }
      }, fps);
    },
  },
};

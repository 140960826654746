<template lang="pug">
.wuwow.card-body(style='padding: 0px')
  .card-body-top
    span.title-avg {{ learningPassionData.attendanceTimeHours }}
    span.title-unit.mx-2 {{ $t('hour') }}
    span.title-avg {{ learningPassionData.attendanceTimeMins }}
    span.title-unit.mx-2 {{ $t('minute') }}
    span.title-text {{ $t('vip.accumulated_class_hours') }}
  .d-flex.flex-row.card-body-btm.flex-wrap
    .card-body-btm-l
      canvas#canvas-frequency.canvas(v-show='fullWidth > PadWidth' width='320' height='320')
      canvas#canvas-frequency-mobile.canvas(v-show='fullWidth <= PadWidth' width='320' height='200')
      .canvas-text.canvas-text-frequency
        .canvas-digital {{ learningPassionData.thirtyDaysAverage }}
        .canvas-unit(style='font-size: 21px')
          | {{ `${$t('class.class')}/${$t('day')}` }}
      .canvas-title {{ $t('vip.learning_frequency') }}
    .card-body-btm-r.flex-column.bd-highlight
      div(v-for='list in frequencyDataList' :key='list.text')
        .btm-r-list.btm-r-list-frequency.d-flex.align-items-baseline(:class='list.class')
          .right-digital.col-7
            | {{ list.value }}
            span.right-unit {{ $t('class.class') }}
          .right-text.col-5 {{ list.text }}
</template>

<script>
import drawCanvas from '@/components/vip/learningPassion/mixin/drawCanvas';
import screenConstants from '@/constants/screenSize';
import calculationConstants from '@/constants/calculation';

export default {
  name: 'VipLearningFrequency',

  components: {},

  mixins: [drawCanvas],

  props: {
    learningPassionData: {
      type: Object,
      default: function() {
        return {};
      },
    },
  },

  data() {
    return {
      fullWidth: screenConstants.ZERO_WIDTH,

      PadWidth: screenConstants.PAD_WIDTH,

      frequencyPercent: this.learningPassionData.thirtyDaysAverage * calculationConstants.ONE_HUNDRED,
      frequencyDataList: [
        {
          text: this.$t('single_month') + this.$t('class.maximum_number_of_classes'),
          value: this.learningPassionData.maxMonthCount,
          class: 'list-border-b',
        },
        // {
        //   text:
        //     this.$t('class.has_taken') +
        //     this.$t('class.microclass') +
        //     this.$t('class.class_count'),
        //   value: this.learningPassionData.oneOnThreeShowCount,
        //   class: 'list-border-b',
        // },
        // {
        //   text:
        //     this.$t('class.has_taken') +
        //     this.$t('class.academy_talks') +
        //     this.$t('class.class_count'),
        //   value: this.learningPassionData.lectureCount,
        //   class: 'list-border-b',
        // },
        {
          text: '已上課堂數',
          value: this.learningPassionData.oneOnOneShowCount,
          class: '',
        },
      ],
    };
  },

  created() {
    this.getWindowSize();
  },

  mounted() {
    this.reDrawCanvas();
  },

  methods: {
    reDrawCanvas() {
      this.drawCanvas('canvas-frequency', this.frequencyPercent, 110, 20);
      this.drawCanvas('canvas-frequency-mobile', this.frequencyPercent, 80, 15);
    },

    getWindowSize() {
      this.fullWidth = window.innerWidth;
      window.onresize = () => {
        this.fullWidth = window.innerWidth;
      };
    },
  },
};
</script>

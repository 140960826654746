<script>
import progressAnalysisConstants from '@/constants/progressAnalysis';
import { inRange } from 'lodash';
export default {
  data() {
    return {
      progress_begin_year: progressAnalysisConstants.PROGRESS_BEGIN_YEAR,

      year: new Date().getFullYear(),

      this_year: new Date().getFullYear(),

      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              stacked: true,
              ticks: {
                min: progressAnalysisConstants.CHART.TICKS_MIN,
                max: progressAnalysisConstants.CHART.TICKS_MAX,
              },
            },
          ],
          xAxes: [
            {
              display: true,
              ticks: {
                fontSize: progressAnalysisConstants.CHART.FONT_SIZE,
              },
            },
          ],
        },
        legend: {
          display: false,
        },
      },
    };
  },
  methods: {
    isObjectEmpty(object) {
      return Object.keys(object).length === 0;
    },

    SetLearningPassionText(avg) {
      if (inRange(avg, progressAnalysisConstants.DAILY_CLASS_MOUNT.LEVEL_ONE, progressAnalysisConstants.DAILY_CLASS_MOUNT.LEVEL_TWO)) {
        return this.$t('learning_passion.passion_title_1');
      } else if (inRange(avg, progressAnalysisConstants.DAILY_CLASS_MOUNT.LEVEL_TWO, progressAnalysisConstants.DAILY_CLASS_MOUNT.LEVEL_THREE)) {
        return this.$t('learning_passion.passion_title_2');
      } else if (inRange(avg, progressAnalysisConstants.DAILY_CLASS_MOUNT.LEVEL_THREE, progressAnalysisConstants.DAILY_CLASS_MOUNT.LEVEL_FOUR)) {
        return this.$t('learning_passion.passion_title_3');
      } else if (inRange(avg, progressAnalysisConstants.DAILY_CLASS_MOUNT.LEVEL_FOUR, progressAnalysisConstants.DAILY_CLASS_MOUNT.LEVEL_FIVE)) {
        return this.$t('learning_passion.passion_title_4');
      } else if (inRange(avg, progressAnalysisConstants.DAILY_CLASS_MOUNT.LEVEL_FIVE, progressAnalysisConstants.DAILY_CLASS_MOUNT.LEVEL_SIX)) {
        return this.$t('learning_passion.passion_title_5');
      } else if (inRange(avg, progressAnalysisConstants.DAILY_CLASS_MOUNT.LEVEL_SIX, progressAnalysisConstants.DAILY_CLASS_MOUNT.LEVEL_SEVEN)) {
        return this.$t('learning_passion.passion_title_6');
      } else if (inRange(avg, progressAnalysisConstants.DAILY_CLASS_MOUNT.LEVEL_SEVEN)) {
        return this.$t('learning_passion.passion_title_7');
      }
    },

    setMonthLabelData(){
      const label = [];
      // 1: 1月, 12: 12月
      for (let month = 1; month <= 12; month++){
        label.push(month + this.$t('month'));
      }
      return label;
    },

    yearCount(action){
      const perYear = 1;
      if (action === 'prev') {
        this.year = this.year - perYear;
      } else if (action === 'next') {
        this.year = this.year + perYear;
      }
    },
  },
};
</script>

export default {
  messages: {

    en: {
      originLevel: 'Origin Level',
      currentLevel: 'Current Level',
      finished: 'Finished',
    },

    tw: {
      originLevel: '初始等級',
      currentLevel: '目前等級',
      finished: '已上',
    },

  },
};

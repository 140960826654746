var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h4', {
    staticClass: "vip-name mb-0"
  }, [_vm._v(_vm._s(_vm.analysisOutlineData.englishName))]), _vm.analysisOutlineData.originLevel === 0 ? _c('span', {
    staticClass: "Initial-level"
  }, [_vm._v("pre-class")]) : _c('span', {
    staticClass: "Initial-level"
  }, [_vm._v(_vm._s(`${_vm.$t('vip.origin_level')}: Level ${_vm.analysisOutlineData.originLevel ? _vm.analysisOutlineData.originLevel : ''}`))]), _c('div', {
    staticClass: "avatar"
  }, [_c('canvas', {
    attrs: {
      "id": "canvas-main",
      "height": "245"
    }
  }), _c('div', {
    staticClass: "vip-avatar"
  }, [_c('img', {
    staticClass: "vip-avatar-img",
    attrs: {
      "src": _vm.analysisOutlineData.photo,
      "alt": "vip photo"
    },
    on: {
      "error": _vm.setDefaultImg
    }
  })])]), _c('div', {
    staticClass: "main-text mb-2"
  }, [_c('div', {
    staticClass: "vip-level"
  }, [_vm._v("Level " + _vm._s(_vm.analysisOutlineData.nowLevel)), _c('div', {
    directives: [{
      name: "b-modal",
      rawName: "v-b-modal.tooltip-light-information",
      modifiers: {
        "tooltip-light-information": true
      }
    }],
    staticClass: "information-icon light-information-icon"
  }, [_vm._v("i")])])]), _c('b-modal', {
    attrs: {
      "id": "tooltip-light-information",
      "size": "sm",
      "ok-only": "",
      "ok-title": _vm.$t('close'),
      "title": _vm.$t('upgrade_test.upgrade_directive_light'),
      "centered": "",
      "hide-header-close": "",
      "header-bg-variant": "primary"
    }
  }, [_c('h6', [_c('strong', [_vm._v("升級辦法")])]), _c('span', [_vm._v("完成課程後，若授課顧問判定學員程度已超越所在等級，將為學員進行升級推薦，系統將於24小時後自動升級。")])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
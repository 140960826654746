<template lang="pug">
  .progress-analysis
    b-row
      b-col.card-bg.card-left.text-center(lg='3' align-h='center')
        b-skeleton.mt-3(width="60%")
        b-skeleton.mt-3(width="30%")
        b-skeleton-img.mt-2(height="20")
        b-skeleton.mt-3(width="30%")
        b-skeleton.mt-5(width="90%")
        b-skeleton.mt-3(width="90%")
        b-skeleton.mt-3(width="90%")
      b-col.card-right(lg='8' cols='12' offset-lg='1')
        .display-center
          b-skeleton.mt-3(width="30%")
          b-skeleton.mt-3(width="60%")
          b-skeleton-img.mt-2(height="700")
          b-row.mt-3
            b-col
              b-skeleton(type="button")
            b-col
              b-skeleton(type="button")
            b-col
              b-skeleton(type="button")

</template>

<script type="text/javascript">

export default {
  name: 'ProgressAnalysisSkeleton',
};
</script>
<style scoped>
.display-center{
  margin: 0 auto;
}
</style>

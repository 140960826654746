export default {
  PASSION: 'passion', // 學習熱度
  ANALYSIS: 'analysis', // 進度分析
  PASSION_LIST: {
    ATTENDED_CLASS: 'attended_class', // 已上課堂數
    BOOK_AND_ATTEND: 'book_and_attend', // 訂課出席
    LEARNING_FREQUENCY: 'learning_frequency', // 學習頻率
  },
  ANALYSIS_LIST: {
    OVERALL: 'overall',
    MATERIAL_ANALYSIS: 'material_analysis',
    LEVEL_ANALYSIS: 'level_analysis',
    SUITEABLE: {
      VOCABULARY: 'vocabulary',
      PRONUNCIATION: 'pronunciation',
      GRAMMAR: 'grammar',
      READING: 'reading',
      SPEAKING: 'speaking',
      LISTENING: 'listening',
    },
  },
  CHART: {
    DATA_COLOR: '#1255a0', // Chart圖顏色
    FONT_SIZE: 10, // 文字大小
    TICKS_MIN: 0, // 垂直線預設最低數量
    TICKS_MAX: 12, // 垂直線預設最高數量
  },
  SWIPPER: {
    SLIDES_PER_VIEW: 3, // 一頁顯示數量
  },
  PROGRESS_BEGIN_YEAR: 2017, // 升級起始年
  DAILY_CLASS_MOUNT: { // 每日上課平均數量
    LEVEL_ONE: 0,
    LEVEL_TWO: 0.1,
    LEVEL_THREE: 0.7,
    LEVEL_FOUR: 1,
    LEVEL_FIVE: 1.6,
    LEVEL_SIX: 2,
    LEVEL_SEVEN: 2.4,
  },
};

<template lang='pug'>
  skeleton(:loading="loading")
    template(slot="skeleton-template")
      skeleton-template
    template(slot="origin-template")
      .progress-analysis
        b-row
          b-col.card-bg.card-left.text-center(lg='3' align-h='center')
            .card-mobile-left.mt-4.mb-3.col-lg-12.col-5
              vip-current-experience(v-if="!isObjectEmpty(challengeInfoData) && !isObjectEmpty(analysisOutlineData)" :challengeInfoData="challengeInfoData" :analysisOutlineData="analysisOutlineData")
            .col-lg-12.col-7(style="margin: auto")
              span {{ summerRewardEventInfo.passedAt }}
              vip-current-level-class-info(v-if="!isObjectEmpty(analysisOutlineData)" :analysisOutlineData="analysisOutlineData")
            //- 暑期活動
            .col-lg-12.col-7.d-lg-block.d-none(style="margin: auto" v-if="this.summerRewardEventInfo.fortyClassesCompletionStatus === 'passed'")
              .summerCamp
                .summerCamp-title
                  h2 暑期密集口說挑戰 證書下載
                  p
                    | 證書將於
                    br
                    | 完成 40 堂的 72 小時後開放下載，
                    br
                    | 開放下載截止至12/3
                ul
                  li.summerCamp-card
                    img(:src="require('@/assets/summerCamp/superkid-certif.svg')" alt="certif")
                    h3 線 上 證 書
                    p
                      | 點擊按鈕
                      br
                      | 下載電子結業證書
                    button.btn(type="button" @click="openSummerCampModal" :disabled="!teacherWritedCustomize") 立即下載
                  li.summerCamp-card
                    img(:src="require('@/assets/summerCamp/superkid-grad.svg')" alt="grad")
                    h3 紙 本 證 書
                    p
                      | 完成收件資料填寫，
                      br
                      | 再送文具禮包
                      span.summerCamp-text （ 30 個工作天內寄送 ）
                    button.btn(type="button" @click="applyPaperCertificate" :disabled="!teacherWritedCustomize") 我要申請
          b-col.mt-5.summerCamp-col.d-lg-none(style="border-radius: 10px;" v-if="this.summerRewardEventInfo.fortyClassesCompletionStatus === 'passed'")
            .summerCamp
              .summerCamp-title
                h2 暑期密集口說挑戰 證書下載
                p
                  | 證書將於
                  | 完成 40 堂的 72 小時後開放下載，
                  br
                  | 開放下載截止至12/3
              ul
                li.summerCamp-card
                  img(:src="require('@/assets/summerCamp/superkid-grad.svg')" alt="grad")
                  h3 紙 本 證 書
                  p
                    | 完成收件資料填寫，
                    br
                    | 再送文具禮包
                    br
                    span.summerCamp-text （ 30 個工作天內寄送 ）
                  button.btn(type="button" @click="applyPaperCertificate" :disabled="!teacherWritedCustomize") 我要申請
                li.summerCamp-card
                  img(:src="require('@/assets/summerCamp/superkid-certif.svg')" alt="certif")
                  h3 線 上 證 書
                  p
                    | 點擊按鈕
                    br
                    | 下載電子結業證書
                  button.btn(type="button" @click="openSummerCampModal" :disabled="!teacherWritedCustomize") 立即下載
          b-col.card-right(lg='8' cols='12' offset-lg='1')
            .d-flex.justify-content-center
              h1.btn.btn-primary 學習熱度
              //- b-button-group.btn-type-group
              //-   b-button.btn-type(:class="[showLayout == passion ? 'btn-selected' : 'btn-cancel']" @click="changeShowLayout(passion)") {{ $t('vip.learning_passion') }}
              //-   b-button.btn-type(:class="[showLayout == analysis ? 'btn-selected' : 'btn-cancel']" @click="changeShowLayout(analysis)") {{ $t('layout.progress_analysis') }}
            vip-learning-passion(v-if="!isObjectEmpty(learningPassionData) && showLayout === passion" :learningPassionData="learningPassionData")
            vip-progress-analysis(v-else-if="showLayout === analysis" :materialAnalysisData="materialAnalysisData" :levelClassesCountData="levelClassesCountData" :learningAnalysisData="learningAnalysisData" :analysisOutlineData="analysisOutlineData")
      b-modal(id="summerCamp" size="md" centered :hideHeaderClose="true"  hide-footer)
        .summerCamp-modal
          h2 我的結業證書
          p 即將下載暑期結業證書
          .d-flex.justify-content-center
            button.btn(type="button" @click="closeSummerCampModal") 取消
            button.btn(type="button" @click="downLoadCertificate") 確認下載
</template>

<script type='text/javascript'>
import skeleton from '@/components/skeleton/index.vue';
import skeletonTemplate from '@/views/dojo/account/progressAnalysis/skeleton/index.vue';
import vipCurrentExperience from '@/components/vip/vipCurrentExperience';
import vipCurrentLevelClassInfo from '@/components/vip/vipCurrentLevelClassInfo';
import vipLearningPassion from '@/components/vip/learningPassion';
import vipProgressAnalysis from '@/components/vip/progressAnalysis';
import progressAnalysisMixin from '@/components/vip/mixin/progressAnalysisMixin.vue';
import progressAnalysisConstants from '@/constants/progressAnalysis';
import lioshutanApi from '@/api';
// for 暑期
import { mapState } from 'vuex';

export default {
  name: 'ProgressAnalysis',

  components: {
    vipCurrentExperience,
    vipCurrentLevelClassInfo,
    vipLearningPassion,
    vipProgressAnalysis,
    skeleton,
    skeletonTemplate,
  },

  mixins: [progressAnalysisMixin],

  data() {
    return {
      passion: progressAnalysisConstants.PASSION,
      analysis: progressAnalysisConstants.ANALYSIS,
      showLayout: progressAnalysisConstants.PASSION,
      challengeInfoData: {},
      analysisOutlineData: {},
      learningAnalysisData: {},
      materialAnalysisData: {},
      levelClassesCountData: {},
      learningPassionData: {},
      loading: false,
    };
  },

  computed: {
    ...mapState('summerCamp', ['summerRewardEventInfo', 'teacherWritedCustomize']),
  },

  watch: {},

  async created() {
    this.startLoading();
    await this.getProgressAnalysisData();
    this.endLoading();
  },

  mounted() {
    this.$store.dispatch('summerCamp/getRewardEventOverallPerformance');
  },

  methods: {
    openSummerCampModal() {
      this.$bvModal.show('summerCamp');
    },
    closeSummerCampModal() {
      this.$bvModal.hide('summerCamp');
    },
    downLoadCertificate() {
      this.$bvModal.hide('summerCamp');
      const { href } = this.$router.resolve({ path: '/reviews' });
      window.open(href, '_blank');
    },
    applyPaperCertificate() {
      window.open('https://www.surveycake.com/s/Py2Z2', '_blank');
    },
    startLoading(){
      this.loading = true;
    },

    endLoading(){
      this.loading = false;
    },
    changeShowLayout(type) {
      this.showLayout = type;
    },
    async getProgressAnalysisData(){
      // 需加入權限判斷
      // const userRoleResponse = await lioshutanApi.common.getUserRole();
      const challengeInfoResponse = await lioshutanApi.vip.getChallengeInfo();
      this.challengeInfoData = challengeInfoResponse.data.data;
      const analysisOutlineResponse = await lioshutanApi.vip.getAnalysisOutline();
      this.analysisOutlineData = await analysisOutlineResponse.data.data;
      const learningAnalysisResponse = await lioshutanApi.vip.getLearningAnalysis();
      this.learningAnalysisData = learningAnalysisResponse.data.data;
      const learningPassionResponse = await lioshutanApi.vip.getLearningPassion();
      this.learningPassionData = learningPassionResponse.data.data.data;
      const materialAnalysisResponse = await lioshutanApi.vip.getMaterialAnalysis();
      this.materialAnalysisData = materialAnalysisResponse.data.data;
      const levelClassesCountResponse = await lioshutanApi.vip.getLevelClassesCount();
      this.levelClassesCountData = levelClassesCountResponse.data.data;
    },
  },
};
</script>

<style lang='scss'>
@import '@/styles/vips/progressAnalysis';
@import '@/styles/transition';
</style>

<style lang="scss" scoped>
@import "@/styles/layout/layout.scss";
$summerCamp: #006BDA;
.card-left:has(.summerCamp) {
  margin: -30px 0 0px 0;
  padding-bottom: 30px;
}
.summerCamp-text {
  color: $summerCamp;
}
.summerCamp-col {
  background: none;
  border-radius: 10px;
  @include media-breakpoint-up(sm) {
    background: #fff;
  }
}
.summerCamp {
  background: #fff;
  border-radius: 10px;
  color: #373737;
  padding: 24px 20px;
  text-align: center;
  @include media-breakpoint-up(lg) {
    padding: 0;
    margin-top: 20px;
  }
  ul {
    list-style: none;
    margin-top: 14px;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-around;
    flex-grow: 1;
    flex-wrap: wrap;
    @include media-breakpoint-up(sm) {
      flex-wrap: nowrap;
    }
    @include media-breakpoint-up(lg) {
      display: block;
    }
  }
  li:nth-child(1) {
    margin-bottom: 30px;
    @include media-breakpoint-up(sm) {
      margin-bottom: 0px;
      margin-right: 10px;
    }
    @include media-breakpoint-up(lg) {
      margin-right: 0px;
      margin-bottom: 40px;
    }
  }
  li:nth-child(2) {
    @include media-breakpoint-up(sm) {
      margin-left: 10px;
    }
    @include media-breakpoint-up(lg) {
      margin-left: 0px;
    }
  }
}
.summerCamp-title {
  h2 {
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 14px;
  }
  > p {
    font-size: 14px;
    line-height: 20px;
  }
}
.summerCamp-card {
  background: #E2F4FF;
  border-radius: 10px;
  padding: 10px 20px 30px 40px;
  color: #373737;
  position: relative;
  width: 100%;
  @include media-breakpoint-up(sm) {
    width: 50%;
  }
  @include media-breakpoint-up(lg) {
    padding: 20px 10px 40px 70px;
    width: auto;
  }
  &:has(button:disabled) {
    background: #EDECEA;
  }
  img {
    position: absolute;
    left: 0;
    top: 10%;
    transform: translateX(-25%);
  }
  h3 {
    font-weight: 700;
    font-size: 16px;
    line-height: 23px;
    margin-bottom: 14px;
  }
  p {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 0;
  }
  button {
    color: #fff;
    background:#006BDA;
    position: absolute;
    left: 50%;
    bottom: 0;
    border-radius: 25px;
    transform: translateX(-50%) translateY(10px);
    &:active:not(:disabled),&:hover:not(:disabled) {
      background: #000;
    }
    &:disabled {
      opacity: 1;
      background: rgba(205, 205, 205, 1);
      color: #616161;
    }
  }
}
.summerCamp-modal {
  color: #373737;
  h2 {
    font-weight: 700;
    font-size: 21px;
    line-height: 30px;
    margin-bottom: 40px;
  }
  p {
    font-size: 16px;
    line-height: 23px;
    margin-bottom: 40px;
  }
  .btn {
    width: 148px;
    margin: 0 12px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    &:nth-child(1) {
      color: #006BDA;
      border: 1px solid #006BDA;
      &:active,&:hover {
        color: #fff;
        background: #006BDA;
      }
    }
    &:nth-child(2) {
      color: #fff;
      background: #006BDA;
      &:active,&:hover {
        background: lighten(#006BDA, 5%);
      }
    }
  }
}
</style>

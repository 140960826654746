var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-list-group', {
    staticStyle: {
      "margin": "auto"
    },
    attrs: {
      "flush": ""
    }
  }, _vm._l(_vm.leftList, function (item, index) {
    return _c('b-list-group-item', {
      key: item.key,
      attrs: {
        "href": "#"
      }
    }, [_c('b-row', {
      attrs: {
        "id": 'tooltip-level-information-' + index,
        "align-v": "center"
      }
    }, [_c('b-col', {
      staticClass: "left-list-text",
      attrs: {
        "lg": "5",
        "cols": "5"
      }
    }, [_vm._v(" " + _vm._s(item.key) + " ")]), _c('b-col', {
      staticClass: "left-list-digital",
      attrs: {
        "lg": "6",
        "cols": "6",
        "align-h": "center"
      }
    }, [index == 1 && item.value > 10000 ? _c('div', [_vm._v("∞")]) : _c('div', [_vm._v(_vm._s(item.value))])]), _c('b-col', {
      staticClass: "left-list-text",
      attrs: {
        "lg": "1",
        "cols": "1"
      }
    }, [_c('div', {
      staticClass: "left-list-text"
    }, [_vm._v(_vm._s(item.unit))]), _c('div', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: index == 2,
        expression: "index == 2"
      }],
      staticClass: "information-icon level-information-icon"
    }, [_vm._v(" i ")])])], 1)], 1);
  }), 1), _c('b-tooltip', {
    attrs: {
      "target": "tooltip-level-information-2",
      "triggers": "click"
    }
  }, [_vm._v(" 0 " + _vm._s(_vm.$t('grade')) + " ")])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
<template lang="pug">
.wuwow.card-body(style='padding: 0px')
  .card-body-top
    span.title-avg {{ learningPassionData.thirtyDaysAverage }}
    span.title-unit.mr-2 {{ $t('class.class') }}/{{ $t('day') }}
    //- span.title-text
    //-   | {{ SetLearningPassionText(learningPassionData.thirtyDaysAverage) }}
  .d-flex.flex-row.card-body-btm.flex-wrap
    .card-body-btm-l
      canvas#canvas-attendance.canvas(v-show='fullWidth > PadWidth' width='320' height='320')
      canvas#canvas-attendance-mobile.canvas(v-show='fullWidth <= PadWidth' width='320' height='200')
      .canvas-text
        span.canvas-digital {{ learningPassionData.thirtyDaysShowPercentage }}
        span.canvas-unit %
      .canvas-title {{ $t('vip.attendance_rate') }}
    .card-body-btm-r.flex-column.bd-highlight
      div(v-for='list in attendDataList' :key='list.text')
        .btm-r-list.btm-r-list-frequency.d-flex.align-items-baseline(:class='list.class')
          .right-digital.col-7
            | {{ list.value }}
            span.right-unit {{ $t('class.class') }}
          .right-text.col-5 {{ list.text }}
</template>

<script type="text/javascript">
import drawCanvas from '@/components/vip/learningPassion/mixin/drawCanvas';
import progressAnalysisMixin from '@/components/vip/mixin/progressAnalysisMixin.vue';
import screenConstants from '@/constants/screenSize';

export default {
  name: 'VipBookAndAttend',

  components: {},

  mixins: [drawCanvas, progressAnalysisMixin],

  props: {
    learningPassionData: {
      type: Object,
      default: function() {
        return {};
      },
    },
  },

  data() {
    return {
      fullWidth: screenConstants.ZERO_WIDTH,

      PadWidth: screenConstants.PAD_WIDTH,

      attendancePercent: this.learningPassionData.thirtyDaysShowPercentage,

      attendDataList: [
        { text: this.$t('class.book'), value: this.learningPassionData.thirtyDaysApplyDatas, class: 'list-border-b' },
        { text: this.$t('class.attend'), value: this.learningPassionData.thirtyDaysShownCount, class: 'list-border-b' },
        { text: this.$t('class.not_attending'), value: this.learningPassionData.thirtyDaysNoShowDatas, class: 'list-border-b' },
        { text: this.$t('class.cancel_booked'), value: this.learningPassionData.thirtyDaysCancelDatas, class: '' },
      ],
    };
  },

  computed: {},

  watch: {},

  created() {
    this.getWindowSize();
  },

  mounted() {
    this.reDrawCanvas();
  },

  methods: {
    reDrawCanvas() {
      this.drawCanvas('canvas-attendance', this.attendancePercent, 110, 20);
      this.drawCanvas('canvas-attendance-mobile', this.attendancePercent, 80, 15);
    },

    getWindowSize() {
      this.fullWidth = window.innerWidth;
      window.onresize = () => {
        this.fullWidth = window.innerWidth;
      };
    },
  },
};
</script>

<template>
  <div>
    <b-list-group flush style="margin: auto">
      <b-list-group-item v-for="(item, index) in leftList" :key="item.key" href="#">
        <b-row :id="'tooltip-level-information-' + index" align-v="center">
          <b-col lg="5" cols="5" class="left-list-text"> {{ item.key }} </b-col>
          <b-col lg="6" cols="6" class="left-list-digital" align-h="center">
            <div v-if="index == 1 && item.value > 10000">∞</div>
            <div v-else>{{ item.value }}</div>
          </b-col>
          <b-col lg="1" cols="1" class="left-list-text">
            <div class="left-list-text">{{ item.unit }}</div>
            <div v-show="index == 2" class="information-icon level-information-icon">
              i
            </div>
          </b-col>
        </b-row>
      </b-list-group-item>
    </b-list-group>
    <b-tooltip
      target="tooltip-level-information-2"
      triggers="click"
    >
      0 {{ $t('grade') }}
    </b-tooltip>
  </div>
</template>

<script type="text/javascript">
export default {
  name: 'VipCurrentLevelClassInfo',

  components: {},

  props: {
    analysisOutlineData: {
      type: Object,
      default: function() {
        return {};
      },
    },
  },

  data() {
    return {
      leftList: [
        {
          key: this.$t('class.number_of_total_classes'),
          value: this.analysisOutlineData.attendCount,
          unit: this.$t('class.class'),
        },
        {
          key: this.$t('class.number_of_remaining'),
          value: this.analysisOutlineData.remainingSessions,
          unit: this.$t('class.class'),
        },
        // {
        //   key: this.$t('vip.average_level_of_past_30_days'),
        //   value: this.analysisOutlineData.monthlyAverageLevel,
        //   unit: this.$t('grade'),
        // },
        { key: this.$t('evaluation.evaluation_feedback_rate'),
          value: this.analysisOutlineData.evaluationPercentage,
          unit: '%',
        },
      ],
    };
  },

  computed: {},

  watch: {
  },

  created() {
  },

  mounted() {

  },

  methods: {
  },
};
</script>

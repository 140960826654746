export default {
  messages: {

    en: {
      confirmDownload: 'Confirm',
      my: 'My',
      upgradeCertificate: 'Upgrade Certificate',
      download: 'Download',
      downloadFailed: 'Download Failed',
      certificateOfAchievement: 'Certificate of Achievement',
    },

    tw: {
      confirmDownload: '確認下載',
      my: '我的',
      upgradeCertificate: '升級證書',
      download: '即將下載',
      downloadFailed: '下載失敗',
      certificateOfAchievement: 'Certificate of Achievement',
    },

  },
};

var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "subtitle"
  }), _vm.layout !== _vm.material_analysis && _vm.layout !== _vm.levelAnalysis ? _c('ability-level', {
    attrs: {
      "layout": _vm.layout,
      "learningAnalysisData": _vm.learningAnalysisData
    }
  }) : _vm.layout === _vm.material_analysis ? _c('material-analysis', {
    attrs: {
      "materialAnalysisData": _vm.materialAnalysisData
    }
  }) : _vm.layout === _vm.levelAnalysis ? _c('level-analysis', {
    attrs: {
      "levelClassesCountData": _vm.levelClassesCountData,
      "analysisOutlineData": _vm.analysisOutlineData
    }
  }) : _vm._e(), _c('div', {
    staticClass: "d-flex justify-content-center flex-wrap"
  }, _vm._l(_vm.analysis_btn, function (btn) {
    return _c('div', {
      key: btn.name
    }, [_c('b-button', {
      staticClass: "progress-btn mt-2",
      class: [_vm.layout === btn.name ? 'btn-selected' : 'btn-cancel'],
      attrs: {
        "variant": "outline-primary",
        "size": "sm"
      },
      on: {
        "click": function ($event) {
          return _vm.onAnalysisBtn(btn.name);
        }
      }
    }, [_vm._v(_vm._s(btn.text))])], 1);
  }), 0)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
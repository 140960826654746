<template lang="pug">
div
  h4.vip-name.mb-0 {{ analysisOutlineData.englishName }}
  span.Initial-level(v-if="analysisOutlineData.originLevel === 0") pre-class
  span.Initial-level(v-else) {{ `${$t('vip.origin_level')}: Level ${analysisOutlineData.originLevel ? analysisOutlineData.originLevel : ''}` }}
  .avatar
    canvas#canvas-main(height='245')
    .vip-avatar
      img.vip-avatar-img(:src='analysisOutlineData.photo' alt='vip photo' @error="setDefaultImg")
  .main-text.mb-2
    .vip-level Level {{ analysisOutlineData.nowLevel }}
      .information-icon.light-information-icon(v-b-modal.tooltip-light-information)
        | i
    //- span.exp-title {{ $t('experience') }}:
    //- span.experience() {{ `${challengeInfoData.now_exp} / ${challengeInfoData.level_total_exp}` }}

  //- JR沒有升級挑戰 先拿掉
  //- b-button.mt-3(v-if='challengeInfoData.in_exam_flag && analysisOutlineData.nowLevel < upgradeChallengeLimits' variant='primary' size='sm' @click='toUpgradeTestPage()')
  //-   | {{ $t('upgrade_test.return_upgrade_test') }}
  //- b-button.mt-3(v-if='challengeInfoData.status && analysisOutlineData.nowLevel < upgradeChallengeLimits' variant='primary' size='sm' @click='toUpgradeTestView()')
  //-   | {{ $t('upgrade_test.upgrade_test') }}
  b-modal#tooltip-light-information(size='sm' ok-only :ok-title="$t('close')" :title="$t('upgrade_test.upgrade_directive_light')" centered hide-header-close header-bg-variant='primary')
    h6
      strong 升級辦法
    span 完成課程後，若授課顧問判定學員程度已超越所在等級，將為學員進行升級推薦，系統將於24小時後自動升級。

</template>

<script type="text/javascript">
import upgradeConstants from '@/constants/upgrade';
import { setDefaultImg } from '@/utils/img.js';
import { getDateWithTimeMinute } from '@/utils/moment';
import drawCanvas from '@/components/vip/learningPassion/mixin/drawCanvas';
export default {
  name: 'VipCurrentExperience',

  components: {},

  mixins: [drawCanvas],

  props: {
    challengeInfoData: {
      type: Object,
      default: function() {
        return {};
      },
    },

    analysisOutlineData: {
      type: Object,
      default: function() {
        return {};
      },
    },
  },

  data() {
    return {
      zeroRecommend: upgradeConstants.CONSULTANT_RECOMMEND_COUNT.ZERO,
      firstRecommend: upgradeConstants.CONSULTANT_RECOMMEND_COUNT.FIRST,
      secondRecommend: upgradeConstants.CONSULTANT_RECOMMEND_COUNT.SECOND,
      thirdRecommend: upgradeConstants.CONSULTANT_RECOMMEND_COUNT.THIRD,
      upgradeChallengeLimits: upgradeConstants.UPGRADE_CHALLENGE_LIMITS,
    };
  },

  mounted() {
    this.getExpDraw();
  },

  methods: {
    toUpgradeTestPage() {
      // TODO NOTICE 等頁面新增router後會再確認一次 name是否正確 By Asa
      this.$router.push({ name: 'vipTest' });
    },

    toUpgradeTestView() {
      // TODO NOTICE 等頁面新增router後會再確認一次 name是否正確 By Asa
      this.$router.push({ name: 'upgradeTestView' });
    },

    getExpDraw() {
      let percent;
      if (
        this.challengeInfoData.now_exp !== null &&
        this.challengeInfoData.level_total_exp !== null
      ) {
        percent =
          (this.challengeInfoData.now_exp / this.challengeInfoData.level_total_exp) * 100;
      } else {
        percent = 0;
      }
      this.drawCanvas('canvas-main', percent, 110, 15);
    },

    setDefaultImg(element) {
      setDefaultImg(element);
    },

    getDateWithTimeMinute(time){
      getDateWithTimeMinute(time);
    },
  },
};
</script>

<template lang="pug">
b-modal#download-certificate-modal(
    size='sm'
    :ok-title="$t('confirmDownload')"
    :cancel-title="$t('close')"
    :title="$t('my') + $t('upgradeCertificate')"
    centered hide-header-close
    header-bg-variant='primary'
    @ok='queryLevelCertificate()'
  )
  span.modal-Certificate-text
    | {{`${$t('download') } Level ${ downloadCertificateData.nowLevel } ${ $t('upgradeCertificate') } `}}
</template>

<script type="text/javascript">
import { alertFailedMessage } from '@/utils/sweetAlert';
import { setParams, downloadPdfFile } from '@/utils';
import lioshutanApi from '@/api';
import i18n from '@/components/vip/progressAnalysis/downloadCertificateModal/lang';

export default {
  name: 'DownloadCertificateModal',

  i18n,

  props: {
    downloadCertificateData: {
      type: Object,
      default: function() {
        return {};
      },
    },
  },

  data() {
    return {
    };
  },

  methods: {
    async queryLevelCertificate(){
      try {
        const params = this.getCertificateDownloadParams(this.downloadCertificateData);
        const response = await lioshutanApi.vip.getLevelCertificate(params);
        const pdfFileContent = response.data;
        const pdfFileName = `Lv${this.downloadCertificateData.nowLevel} ${this.$t('certificateOfAchievement')}`;
        downloadPdfFile(pdfFileContent, pdfFileName);
      } catch (error) {
        alertFailedMessage(this.$t('downloadFailed'));
      }
    },
    getCertificateDownloadParams(downloadCertificateData){
      const downloadCertificateKeys = {
        englishName: 'en',
        chineseName: 'cn',
        totalClasses: 'classes',
        nowLevel: 'nl',
        preLevel: 'll',
      };
      const params = setParams(downloadCertificateKeys, downloadCertificateData);
      return params;
    },
  },
};
</script>

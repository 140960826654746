<template lang="pug">
div
  .wuwow.card-body.card-bg
    .are-progress-type.d-flex.align-items-center.justify-content-center
      // 技術債與 attendClass.vue 相同寫法
      font-awesome-icon.title-btn(icon='caret-left' size='2x' :class="[year <= progress_begin_year ? 'title-btn-disable' : '']" @click="onYearChange('prev', !(year <= progress_begin_year))")
      span.title-y.text-center  {{ year }}{{ $t('year') }}
      font-awesome-icon.title-btn(icon='caret-right' size='2x' :class="[year >= this_year ? 'title-btn-disable' : '']" @click="onYearChange('next', !(year >= this_year))")
    .card-body-title {{ chart_title }}
    .are-progress-chart.are-progress-chart-other
      line-chart(:key='options.scales.yAxes[0].ticks.max' :chart-data='dataCollection' :options='options')
</template>

<script type="text/javascript">
import lineChart from '@/components/chart/lineChart';
import progressAnalysisMixin from '@/components/vip/mixin/progressAnalysisMixin.vue';
import progressAnalysisConstants from '@/constants/progressAnalysis';

export default {
  name: 'VipAbilityLevel',

  components: {
    lineChart,
  },

  mixins: [progressAnalysisMixin],

  props: {
    layout: {
      type: String,
      default: function() {
        return progressAnalysisConstants.ANALYSIS_LIST.OVERALL;
      },
    },

    learningAnalysisData: {
      type: Object,
      default: function() {
        return {};
      },
    },
  },

  data() {
    return {
      chart_title: this.$t(progressAnalysisConstants.ANALYSIS_LIST.OVERALL),

      dataCollection: {},
    };
  },

  watch: {
    layout(value) {
      this.setChartTitle(value);
      this.setAbilityLevelChartData();
    },
  },

  mounted(){
    this.setAbilityLevelChartData();
  },

  methods: {
    onYearChange(action, approve) {
      if (approve){
        this.yearCount(action);
        this.setAbilityLevelChartData();
      }
    },

    setChartTitle(name) {
      this.chart_title = this.$t(name);
    },

    setAbilityLevelChartData(){
      const labels = this.setMonthLabelData(),
            data = this.setChartDataAndOptions(this.year);
      this.dataCollection = {
        labels,
        datasets: [
          {
            label: this.$t('classes'),
            backgroundColor: '#1255a0',
            borderColor: '#1255a0',
            data,
            fill: false,
          },
        ],
      };
    },

    setChartDataAndOptions(year){
      const data = [];
      let level;
      switch (this.layout) {
        case progressAnalysisConstants.ANALYSIS_LIST.OVERALL:
          level = this.learningAnalysisData.average_level_average[year];
          break;
        case progressAnalysisConstants.ANALYSIS_LIST.SUITEABLE.VOCABULARY:
          level = this.learningAnalysisData.vocabulary_level_average[year];
          break;
        case progressAnalysisConstants.ANALYSIS_LIST.SUITEABLE.PRONUNCIATION:
          level = this.learningAnalysisData.pronunciation_level_average[year];
          break;
        case progressAnalysisConstants.ANALYSIS_LIST.SUITEABLE.GRAMMAR:
          level = this.learningAnalysisData.grammar_level_average[year];
          break;
        case progressAnalysisConstants.ANALYSIS_LIST.SUITEABLE.READING:
          level = this.learningAnalysisData.reading_level_average[year];
          break;
        case progressAnalysisConstants.ANALYSIS_LIST.SUITEABLE.SPEAKING:
          level = this.learningAnalysisData.speaking_level_average[year];
          break;
        case progressAnalysisConstants.ANALYSIS_LIST.SUITEABLE.LISTENING:
          level = this.learningAnalysisData.listening_level_average[year];
          break;
        default:
          level = this.learningAnalysisData.average_level_average[year];
          break;
      }

      if (level) {
        // eslint-disable-next-line
        for (const month in level) {
          data[month - 1] = level[month];
        }
      }
      this.options.scales.yAxes[0].ticks.max = 12;
      this.options.scales.yAxes[0].ticks.stepSize = 1;
      return data;
    },
  },
};
</script>

<template lang="pug">
.wuwow.card-body
  .card-body-top.card-body-top-passion
    span.title-avg {{ learningPassionData.thirtyDaysAverage }}
    span.title-unit.mr-2 {{ `${$t('class.class')}/${$t('day')}` }}
    //- span.title-text
    //-   | {{ SetLearningPassionText(learningPassionData.thirtyDaysAverage )}}
  .are-progress-type.d-flex.align-items-center.justify-content-center
    // 技術債與 abilityLevel.vue 相同寫法
    font-awesome-icon.title-btn(
      icon='caret-left'
      size='2x'
      :class="{'title-btn-disable': year <= progress_begin_year}"
      @click="onYearChange('prev', !(year <= progress_begin_year))"
    )
    span.title-y.text-center  {{`${year}${$t('year')}`}}
    font-awesome-icon.title-btn(
      icon='caret-right'
      size='2x'
      :class="{'title-btn-disable': year >= this_year}"
      @click="onYearChange('next', !(year >= this_year))"
    )
  .are-progress-chart
    line-chart(:key='options.scales.yAxes[0].ticks.max' :chart-data='dataCollection' :options='options')
  .passion-chart-footer
    span {{ `${year} ${$t('year')}${$t('class.number_of_total_classes')}` }}:
    span.passion-chart-footer-value(v-if='learningPassionData.yearlyDatas && learningPassionData.yearlyDatas[year]')
      | {{ learningPassionData.yearlyDatas[year].total }}
    span.passion-chart-footer-value(v-else)
      | 0
    span {{ $t('class.class') }}
</template>

<script type="text/javascript">
import ProgressAnalysisConstants from '@/constants/progressAnalysis';
import lineChart from '@/components/chart/lineChart';
import ProgressAnalysisMixin from '@/components/vip/mixin/progressAnalysisMixin.vue';

export default {
  name: 'VipAttendedClass',

  components: {
    lineChart,
  },

  mixins: [ProgressAnalysisMixin],

  props: {
    learningPassionData: {
      type: Object,
      default: function() {
        return {};
      },
    },
  },

  data() {
    return {
      dataCollection: {},
    };
  },

  computed: {},

  watch: {},

  created() {
    this.setAttendClassChartData();
  },

  methods: {
    onYearChange(action, approve) {
      if (approve){
        this.yearCount(action);
        this.setAttendClassChartData();
      }
    },

    setAttendClassChartData() {
      const labels = this.setMonthLabelData(),
            data = this.setChartDataAndOptions(this.year);
      this.dataCollection = {
        labels,
        datasets: [
          {
            label: this.$t('classes'),
            backgroundColor: ProgressAnalysisConstants.CHART.DATA_COLOR,
            borderColor: ProgressAnalysisConstants.CHART.DATA_COLOR,
            data,
            fill: false,
          },
        ],
      };
    },

    setChartDataAndOptions(year){
      // TODO NOTICE 補常數 BY Asa
      let data = [];
      if (this.learningPassionData.yearlyDatas && this.learningPassionData.yearlyDatas[year]) {
        data = Object.values(this.learningPassionData.yearlyDatas[year]);
        data.splice(data.length - 1, 1);
        this.options.scales.yAxes[0].ticks.max = Math.max(...data) + 20;
        this.options.scales.yAxes[0].ticks.stepSize = Math.floor(
          this.options.scales.yAxes[0].ticks.max / 10
        );
      } else {
        this.options.scales.yAxes[0].ticks.max = 100;
        this.options.scales.yAxes[0].ticks.stepSize = 10;
      }
      return data;
    },

  },
};
</script>

<template lang="pug">
div
  .subtitle
  ability-level(v-if='layout !== material_analysis && layout !== levelAnalysis' :layout='layout' :learningAnalysisData='learningAnalysisData')
  material-analysis(v-else-if='layout === material_analysis' :materialAnalysisData='materialAnalysisData')
  level-analysis(v-else-if='layout === levelAnalysis' :levelClassesCountData='levelClassesCountData' :analysisOutlineData='analysisOutlineData')
  .d-flex.justify-content-center.flex-wrap
    div(v-for='btn in analysis_btn' :key='btn.name')
      b-button.progress-btn.mt-2(variant='outline-primary' size='sm' :class="[layout ===  btn.name? 'btn-selected' : 'btn-cancel']" @click='onAnalysisBtn(btn.name)')
        | {{ btn.text }}
</template>

<script type="text/javascript">
import abilityLevel from '@/components/vip/progressAnalysis/abilityLevel';
import levelAnalysis from '@/components/vip/progressAnalysis/levelAnalysis';
import materialAnalysis from '@/components/vip/progressAnalysis/materialAnalysis';
import progressAnalysisConstants from '@/constants/progressAnalysis';

export default {
  name: 'VipProgressAnalysis',

  components: {
    abilityLevel,
    levelAnalysis,
    materialAnalysis,
  },

  props: {
    materialAnalysisData: {
      type: Object,
      default: function() {
        return {};
      },
    },

    levelClassesCountData: {
      type: Object,
      default: function() {
        return {};
      },
    },

    learningAnalysisData: {
      type: Object,
      default: function() {
        return {};
      },
    },

    analysisOutlineData: {
      type: Object,
      default: function() {
        return {};
      },
    },
  },

  data() {
    return {
      layout: progressAnalysisConstants.ANALYSIS_LIST.OVERALL,
      material_analysis: progressAnalysisConstants.ANALYSIS_LIST.MATERIAL_ANALYSIS,
      levelAnalysis: progressAnalysisConstants.ANALYSIS_LIST.LEVEL_ANALYSIS,
      analysis_btn: [
        { name: progressAnalysisConstants.ANALYSIS_LIST.OVERALL, text: this.$t('overall') },
        { name: progressAnalysisConstants.ANALYSIS_LIST.SUITEABLE.VOCABULARY, text: this.$t('vocabulary') },
        { name: progressAnalysisConstants.ANALYSIS_LIST.SUITEABLE.PRONUNCIATION, text: this.$t('pronunciation') },
        { name: progressAnalysisConstants.ANALYSIS_LIST.SUITEABLE.GRAMMAR, text: this.$t('grammar') },
        { name: progressAnalysisConstants.ANALYSIS_LIST.SUITEABLE.READING, text: this.$t('reading') },
        { name: progressAnalysisConstants.ANALYSIS_LIST.SUITEABLE.SPEAKING, text: this.$t('speaking') },
        { name: progressAnalysisConstants.ANALYSIS_LIST.SUITEABLE.LISTENING, text: this.$t('listening') },
        { name: progressAnalysisConstants.ANALYSIS_LIST.MATERIAL_ANALYSIS, text: this.$t('material.material_analysis') },
        { name: progressAnalysisConstants.ANALYSIS_LIST.LEVEL_ANALYSIS, text: this.$t('level') + this.$t('analysis') },
      ],
    };
  },

  methods: {
    onAnalysisBtn(layout) {
      this.layout = layout;
    },
  },
};
</script>

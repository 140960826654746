<template lang="pug">
div
  .subtitle
    span.subtitle-thirty-day(v-show='layout === bookAndAttend')
      | {{ `*${$t('last_30_days_statistics')}` }}
  .reg-progress
    .wuwow-card.d-flex.flex-column
      attended-class(v-if='!isObjectEmpty(learningPassionData) && layout === attendedClass' :learningPassionData='learningPassionData')
      book-and-attend(v-else-if='layout === bookAndAttend' :learningPassionData='learningPassionData')
      learning-frequency(v-else-if='layout === learningFrequency' :learningPassionData='learningPassionData')
  .d-flex.justify-content-center.flex-wrap.mt-2
    div(v-for='btn in passionBtn' :key='btn.text')
      b-button.progress-btn.mt-2(
        variant='outline-primary'
        size='sm'
        :class="[layout ===  btn.name? 'btn-selected' : 'btn-cancel']"
        @click='onPassionBtn(btn.name)'
      )
        | {{ btn.text }}
</template>

<script type="text/javascript">
import attendedClass from '@/components/vip/learningPassion/attendedClass';
import bookAndAttend from '@/components/vip/learningPassion/bookAndAttend';
import learningFrequency from '@/components/vip/learningPassion/learningFrequency';
import progressAnalysisMixin from '@/components/vip/mixin/progressAnalysisMixin.vue';
import progressAnalysisConstants from '@/constants/progressAnalysis';

export default {
  name: 'VipLearningPassion',

  components: {
    attendedClass,
    bookAndAttend,
    learningFrequency,
  },

  mixins: [progressAnalysisMixin],

  props: {
    learningPassionData: {
      type: Object,
      default: function() {
        return {};
      },
    },
  },

  data() {
    return {
      layout: progressAnalysisConstants.PASSION_LIST.ATTENDED_CLASS,
      attendedClass: progressAnalysisConstants.PASSION_LIST.ATTENDED_CLASS,
      bookAndAttend: progressAnalysisConstants.PASSION_LIST.BOOK_AND_ATTEND,
      learningFrequency: progressAnalysisConstants.PASSION_LIST.LEARNING_FREQUENCY,
      passionBtn: [
        { name: progressAnalysisConstants.PASSION_LIST.ATTENDED_CLASS, text: this.$t('vip.attended_class') },
        { name: progressAnalysisConstants.PASSION_LIST.BOOK_AND_ATTEND, text: this.$t('vip.book_and_attend') },
        { name: progressAnalysisConstants.PASSION_LIST.LEARNING_FREQUENCY, text: this.$t('vip.learning_frequency') },
      ],
    };
  },

  computed: {},

  watch: {},

  created() {},

  methods: {
    onPassionBtn(layout) {
      this.layout = layout;
    },
  },
};
</script>

<template lang="pug">
.wuwow.card-body.card-bg(style='padding: 0; width: 100%')
  .progress-grade-swiper
    swiper(ref='mySwiper' :key='analysisOutlineData.nowLevel' :options='swiperOption')
      swiper-slide.d-flex.justify-content-center.py-lg-0.py-4(v-for='item in swiperList' :key='item.level')
        .text-center.d-flex.flex-column.justify-content-center.align-items-center
          .d-flex.align-items-end.justify-content-center(style='height: 10%')
            span.initial-level.px-3(v-show='item.level === analysisOutlineData.originLevel')  {{ `${$t('originLevel')}` }}
            span.current-level.px-3(v-show='item.level === analysisOutlineData.nowLevel')  {{ `${$t('currentLevel')}` }}
          .level(:class="{'level-un':\
            item.level > analysisOutlineData.nowLevel ||\
            item.level < analysisOutlineData.originLevel}"
          )
            | {{ `Level${item.level}` }}
          .circle.d-flex.justify-content-center.align-items-center.flex-column.my-lg-2.my-1(:class="{'level-un':\
            item.level > analysisOutlineData.nowLevel ||\
            item.level < analysisOutlineData.originLevel}"
          )
            div {{ $t('finished') }}
            div
              span {{ item.class }}
              span(clelsv-elses='circle-unit') {{ `${$t('class.class')}` }}
          .avg-upgrade(v-if='item.level > 1')
            | {{ `${$t('average')}${$t('upgrade_test.upgrade')}${$t('class.class_count')}:${item.avgUpgrade}${$t('class.class')}` }}
          .d-flex.align-items-start.justify-content-center(style='height: 10%')
            button.btn.btn-primary.btn-sm.btn-certificate(
                v-b-modal.download-certificate-modal
                v-if="canDownloadCertificate(item.level)"
                @click='setCertificateModalData(item.level)'
              )
              | {{ `${$t('download')}${$t('upgrade_test.upgrade')}${$t('upgrade_test.certificate')}` }}
      // Optional controls
      .swiper-pagination(slot='pagination')
      font-awesome-icon.title-btn.swiper-button-prev(slot='button-prev' icon='caret-left' size='5x')
      font-awesome-icon.title-btn.swiper-button-next(slot='button-next' icon='caret-right' size='5x')
  download-certificate-modal(:downloadCertificateData='downloadCertificateData' :key='downloadCertificateData.nl')

</template>

<script type="text/javascript">
import upgradeConstants from '@/constants/upgrade';
import progressAnalysisConstants from '@/constants/progressAnalysis';
import { swiper, swiperSlide } from 'vue-awesome-swiper';
import 'swiper/dist/css/swiper.min.css';
import downloadCertificateModal from '@/components/vip/progressAnalysis/downloadCertificateModal';
import i18n from '@/components/vip/progressAnalysis/levelAnalysis/lang';

export default {
  name: 'VipLevelAnalysis',

  components: {
    swiper,
    swiperSlide,
    downloadCertificateModal,
  },

  i18n,

  props: {
    levelClassesCountData: {
      type: Object,
      default: function() {
        return {};
      },
    },

    analysisOutlineData: {
      type: Object,
      default: function() {
        return {};
      },
    },
  },

  data() {
    return {
      downloadCertificateData: {},
      // TODO NOTICE 須從後端帶入 By Asa
      swiperList: [
        { level: 1, class: 0, avgUpgrade: upgradeConstants.AVERAGE_UPGRADE.LEVEL_ONE },
        { level: 2, class: 0, avgUpgrade: upgradeConstants.AVERAGE_UPGRADE.LEVEL_TWO },
        { level: 3, class: 0, avgUpgrade: upgradeConstants.AVERAGE_UPGRADE.LEVEL_THREE },
        { level: 4, class: 0, avgUpgrade: upgradeConstants.AVERAGE_UPGRADE.LEVEL_FOUR },
        { level: 5, class: 0, avgUpgrade: upgradeConstants.AVERAGE_UPGRADE.LEVEL_FIVE },
        { level: 6, class: 0, avgUpgrade: upgradeConstants.AVERAGE_UPGRADE.LEVEL_SIX },
        { level: 7, class: 0, avgUpgrade: upgradeConstants.AVERAGE_UPGRADE.LEVEL_SEVEN },
        { level: 8, class: 0, avgUpgrade: upgradeConstants.AVERAGE_UPGRADE.LEVEL_EIGHT },
        { level: 9, class: 0, avgUpgrade: upgradeConstants.AVERAGE_UPGRADE.LEVEL_NINE },
        { level: 10, class: 0, avgUpgrade: upgradeConstants.AVERAGE_UPGRADE.LEVEL_TEN },
        { level: 11, class: 0, avgUpgrade: upgradeConstants.AVERAGE_UPGRADE.LEVEL_ELEVEN },
        { level: 12, class: 0, avgUpgrade: upgradeConstants.AVERAGE_UPGRADE.LEVEL_TWELVE },
      ],

      swiperOption: {
        pagination: {
          el: '.swiper-pagination',
        },
        prevButton: '.swiper-button-prev',
        nextButton: '.swiper-button-next',
        slidesPerView: progressAnalysisConstants.SWIPPER.SLIDES_PER_VIEW,
      },
    };
  },

  mounted() {
    this.setLevelClassCount();
  },

  methods: {
    setLevelClassCount(){
      // eslint-disable-next-line
      for (const level in this.levelClassesCountData) {
        this.swiperList[level - 1].class = this.levelClassesCountData[level];
      }
    },
    // TODO NOTICE 規則須拉到後端已在[學生-課程紀錄(太外)]卡片上新增 By Asa
    canDownloadCertificate(level){
      return level > this.analysisOutlineData.originLevel && level <= this.analysisOutlineData.nowLevel && level !== 1;
    },

    setCertificateModalData(level){
      this.downloadCertificateData = {};
      this.downloadCertificateData.englishName = this.analysisOutlineData.englishName;
      this.downloadCertificateData.chineseName = this.analysisOutlineData.chineseName;
      // 前一等級上課堂數
      this.downloadCertificateData.totalClasses = this.swiperList[level - 2].class;
      // 升等等級
      this.downloadCertificateData.nowLevel = level;
      // 前一等級
      this.downloadCertificateData.preLevel = level - 1;
    },
  },
};
</script>
